import React, { useState, useEffect, useRef } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick"

import useWindowWidth from "../helpers/useWindowWidth"
import SliderBg from "../img/slider_bg.png"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Link from "../components/Link"
import GetIcon from "../components/GetIcon"
import { SliderArrow, QuoteIconTop, QuoteIconBottom } from "../components/Icons"
import ProjectContainer from "../components/ProjectContainer"
import { CorporationSchema } from "../schemas-org"

import locales from "../locales.json"

import "slick-carousel/slick/slick.css"

const query = graphql`
  query HomeQueryDE {
    datoCmsHomePage(locale: { eq: "de" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading
      description
      homeButton
      whatHeading
      whatDescription
      whatContent {
        icon
        heading
        description
        link
      }
       buttonCta
      callToAction
      companiesHeading
      slider {
        image {
          gatsbyImageData(width: 200, height: 200, layout: FIXED)
          alt
        }
        title
        subtitle
        text
      }
      logos {
        image {
          url
          alt
        }
      }
    }
    allDatoCmsProject(
      filter: { showOnHome: { eq: true }, locale: { eq: "de" } }
      limit: 4
    ) {
      nodes {
        mainImage {
          gatsbyImageData(width: 600)
          alt
        }
        projectBackground {
          rgb
        }
        slug
        title
        shortDescription
        customer
      }
    }
  }
`

const Index = props => {
  const lang = props.pageContext.langKey
  const data = useStaticQuery(query)
  const {
    seo,
    heading,
    description,
    homeButton,
    whatHeading,
    whatDescription,
    whatContent,
    companiesHeading,
    slider,
    logos,
    buttonCta,
    callToAction
  } = data.datoCmsHomePage
  const projects = data.allDatoCmsProject.nodes
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 600,
  }
  const settingsLogos = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 4000,
    slidesToShow: 7,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false,
    pauseOnFocus: false,
  }

  const window = useWindowWidth()
  const totalSlides = slider.length
  const [slideIndex, setSlideIndex] = useState(0)
  const sliderRef = useRef(null)
  const prevSlideHandler = () => {
    const currentSlide = slideIndex
    const nextSlide = currentSlide === 0 ? totalSlides - 1 : currentSlide - 1
    setSlideIndex(nextSlide)
  }
  const nextSlideHandler = () => {
    const currentSlide = slideIndex
    const nextSlide = currentSlide === totalSlides - 1 ? 0 : currentSlide + 1
    setSlideIndex(nextSlide)
  }

  useEffect(() => {
    sliderRef.current.slickGoTo(slideIndex)
  }, [slideIndex])

  return (
    <Layout lang={lang} location={props.location} whiteNav isHome buttonCta={buttonCta} callToAction={callToAction}>
      <Seo
        title={seo.title ?? ""}
        description={seo.description ?? ""}
        meta={seo.description ?? ""}
        image={seo && seo.image && seo.image.url}
        schemaMarkup={CorporationSchema}
      />
      <div className="home__main background__black home_hero">
        <div className="home__main--gradient" />
        <div className="home__main--content">
          <div className="container">
            <div className="home__main--container">
              <div className="home__main--text">
                <h1
                  className="home__heading"
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
                <p
                  className="text"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
                <div className="btn__primary--animation">
                  <Link className="btn btn__primary" to="/contact/" lang={lang}>
                    {homeButton}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Slider {...settingsLogos} className="logos-slider">
          {logos.map((logo, index) => {
            const { image } = logo
            return (
              <img
                className="home__companies--logo"
                src={image.url}
                key={index}
                alt={image.alt}
                height="36"
              />
            )
          })}
        </Slider>
        <div className="home__companies--list mobile">
          {logos.map((logo, index) => {
            const { image } = logo
            return (
              <img
                className="home__companies--logo"
                src={image.url}
                key={index}
                alt={image.alt}
                height="20"
              />
            )
          })}
        </div>
      </div>
      <div className="home__what">
        <div className="container">
          <h3
            className="home__what--heading"
            dangerouslySetInnerHTML={{ __html: whatHeading }}
          />
          <p
            className="text"
            dangerouslySetInnerHTML={{ __html: whatDescription }}
          />
          <div className="home__what--slider">
            {whatContent.map((box, index) => (
              <div className="home__what--box" key={index}>
                <div className="home__what--icon">
                  <GetIcon icon={box.icon} />
                </div>
                <div className="home__what--content">
                  <h3 className="heading color__blue">{box.heading}</h3>
                  <p dangerouslySetInnerHTML={{ __html: box.description }} />
                </div>
                {box.link && (
                  <Link
                    lang={lang}
                    to={box.link}
                    className="btn btn__secondary"
                  >
                    {"Mehr Info >"}
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="home__work background__grey">
        <div className="container">
          <h3 className="home__work--heading">
            {locales.home.projectsHeading.de}
          </h3>
          <div className="home__work--container">
            <div className="home__work--top">
              <div className="home__work--left">
                <div className="home__project">
                  <ProjectContainer
                    lang={lang}
                    link={projects[0].slug}
                    title={projects[0].title}
                    desc={projects[0].shortDescription}
                    client={projects[0].customer}
                    background={projects[0].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[0].mainImage.gatsbyImageData}
                      alt={projects[0].mainImage.alt}
                      title={projects[0].mainImage.alt} />
                  </ProjectContainer>
                </div>
                <div className="home__project">
                  <ProjectContainer
                    lang={lang}
                    link={projects[1].slug}
                    title={projects[1].title}
                    desc={projects[1].shortDescription}
                    client={projects[1].customer}
                    background={projects[1].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[1].mainImage.gatsbyImageData}
                      alt={projects[1].mainImage.alt}
                      title={projects[1].mainImage.alt} />
                  </ProjectContainer>
                </div>
              </div>
              <div className="home__work--right">
                <div className="home__project">
                  <ProjectContainer
                    lang={lang}
                    link={projects[2].slug}
                    title={projects[2].title}
                    desc={projects[2].shortDescription}
                    client={projects[2].customer}
                    background={projects[2].projectBackground.rgb}
                  >
                    <GatsbyImage
                      image={projects[2].mainImage.gatsbyImageData}
                      alt={projects[2].mainImage.alt}
                      title={projects[2].mainImage.alt} />
                  </ProjectContainer>
                </div>
              </div>
            </div>
            <div className="home__work--bottom">
              <div className="home__project">
                <ProjectContainer
                  lang={lang}
                  link={projects[3].slug}
                  title={projects[3].title}
                  desc={projects[3].shortDescription}
                  client={projects[3].customer}
                  background={projects[3].projectBackground.rgb}
                >
                  <GatsbyImage
                    image={projects[3].mainImage.gatsbyImageData}
                    alt={projects[3].mainImage.alt}
                    title={projects[3].mainImage.alt} />
                </ProjectContainer>
              </div>
            </div>
          </div>
          <div className="home__work--link">
            <Link lang={lang} to="/our-work/" className="btn btn__secondary">
              {locales.home.allProjectsLink.de}
            </Link>
          </div>
        </div>
      </div>
      <div className="home__companies">
        <div className="container">
          <h2
            className="home__companies--heading"
            dangerouslySetInnerHTML={{ __html: companiesHeading }}
          />
          <div
            className="home__companies--slider background__grey"
            style={{
              backgroundImage: `url(${window.width < 768 ? "" : SliderBg})`,
            }}
          >
            <div className="companies__slider">
              <div className="companies__slider--arrows">
                <div
                  className="arrow prev"
                  onClick={prevSlideHandler}
                  role="button"
                  tabIndex={0}
                  aria-hidden
                >
                  <SliderArrow />
                </div>
                <div
                  className="arrow next"
                  onClick={nextSlideHandler}
                  role="button"
                  tabIndex={0}
                  aria-hidden
                >
                  <SliderArrow />
                </div>
              </div>
              <Slider {...settings} ref={sliderRef}>
                {slider.map((slide, index) => (
                  <div className="slide" key={index}>
                    <div className="companies__slide">
                      <div className="companies__slide--about">
                        <div className="companies__slide--image">
                          <GatsbyImage
                            image={{
                              ...slide.image.gatsbyImageData,
                              height: 130,
                              width: 130,
                              objectFit: "contain",
                            }}
                            alt={slide.image.alt}
                            title={slide.image.alt}
                            className="image" />
                        </div>
                        <div className="companies__slide--text">
                          <h4
                            className="companies__slide--heading"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                          />
                          <p
                            dangerouslySetInnerHTML={{ __html: slide.subtitle }}
                          />
                        </div>
                      </div>
                      <div className="companies__slide--content">
                        <QuoteIconTop />
                        <div dangerouslySetInnerHTML={{ __html: slide.text }} />
                        <QuoteIconBottom />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="companies__slider--navigation">
                {slider.map((slide, index) => (
                  <div
                    key={index}
                    className={`dot ${slideIndex === index ? "active" : ""}`}
                    onClick={() => setSlideIndex(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Index
